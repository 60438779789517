import { useEffect } from 'react';

const useMetaTags = ({ lang, title = '', description = '', canonicalUrl = '', keywords = '' }) => {
  useEffect(() => {
    if (!lang) return;
    document.documentElement.setAttribute('lang', lang);
  }, [lang]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    const metaDescription = document.head.querySelector('meta[name="description"]');
    if (!metaDescription) {
      const element = document.createElement('meta');
      element.name = 'description';
      element.content = description;
      document.head.appendChild(element);
    } else metaDescription.setAttribute('content', description);
  }, [description]);

  useEffect(() => {
    const metaCanonical = document.head.querySelector('link[rel="canonical"]');
    if (!metaCanonical) {
      const element = document.createElement('link');
      element.rel = 'canonical';
      element.href = canonicalUrl;
      document.head.appendChild(element);
    } else metaCanonical.setAttribute('href', canonicalUrl);
  }, [canonicalUrl]);

  useEffect(() => {
    const metaCanonical = document.head.querySelector('meta[name="keywords"]');
    if (!metaCanonical) {
      const element = document.createElement('meta');
      element.name = 'keywords';
      element.href = keywords;
      document.head.appendChild(element);
    } else metaCanonical.setAttribute('content', keywords);
  }, [keywords]);
};

export default useMetaTags;
