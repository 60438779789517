import { useContext, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Popup from 'reactjs-popup';
import classNames from 'classnames';
import { Modal, ThemeContext } from '@forma/forma-ui-kit';
import ClientWrap from '@/components/ClientWrap';
import { MainContext } from '@/context/MainContext';

import styles from './timezone-select.module.css';

const ModalContent = ({ searchValue, onChangeSearch, onClickItem, timezones }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.dropdown}>
      <input
        placeholder={t('search')}
        className={styles.search}
        onChange={e => onChangeSearch(e.target.value.replace(/[-/\\^$*+?.()|[\]{}]/g, ''))}
        value={searchValue}
      />
      <div className={classNames(styles.select, 'styled-scrollbar')}>
        {Object.values(timezones)
          .filter(({ utc, city }) => (
            !!`UTC ${utc} ${city}`.match(new RegExp(searchValue, 'i'))
          ))
          .map(({ utc, city }, index) => (
            <div className={styles.item} onClick={() => onClickItem({ utc, city })} tabIndex="0" key={index}>
              <span dangerouslySetInnerHTML={{
                __html: `UTC ${utc} ${city}`.replace(new RegExp(searchValue, 'i'), `<span class="accent-text">$&</span>`)
              }} />
            </div>
          ))
        }
      </div>
    </div>
  );
};

const TimezoneSelect = ({ value = {}, onChange }) => {
  const { t } = useTranslation();
  const { viewport } = useContext(ThemeContext);
  const { timezones } = useContext(MainContext);
  const [ open, setOpen ] = useState(false);
  const [ searchValue, setSearchValue ] = useState('');

  const handleClickItem = (utc) => {
    onChange(utc);
    setOpen(false);
  };

  if (!timezones) return null;

  const current = Object.values(timezones).find(({ utc, city }) => value.city ? value.city === city : value.utc === utc) ?? timezones[0];

  if (viewport !== 'phone') return (
    <div className={styles.root}>
      <Popup
        trigger={<button type="button" className={styles.button}>UTC {current.utc} {current.city}</button>}
        className="tooltip timezone"
        on={['click']}
        offsetY={0}
        offsetX={0}
        arrow={false}
        position="bottom left"
        closeOnDocumentClick
        nested
      >
        {close => (
          <ModalContent
            searchValue={searchValue}
            onChangeSearch={setSearchValue}
            onClickItem={value => { handleClickItem(value); close(); }}
            timezones={timezones}
          />
        )}
      </Popup>
    </div>
  );

  return (
    <div className={styles.root}>
      <button type="button" className={styles.button} onClick={() => setOpen(!open)}>
        UTC {current.utc} {current.city}
      </button>

      <ClientWrap>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          title={t('timezone')}
          buttons={[{
            className: styles.modalButton,
            viewStyle: 'primary',
            children: t('next')
          }]}
        >
          <ModalContent
            searchValue={searchValue}
            onChangeSearch={setSearchValue}
            onClickItem={handleClickItem}
            timezones={timezones}
          />
        </Modal>
      </ClientWrap>
    </div>
  );
};

export default TimezoneSelect;
