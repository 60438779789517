import { forwardRef, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { nanoid } from '@forma/forma-ui-kit';
import DateTimeModal from './DateTimeModal';

import styles from './date-time-input.module.css';

const Input = forwardRef(({ id = nanoid(), name, onChange, onFocus, onBlur, onUnFocus, type='text', hidden, fullWidth, label, control, className,
  maxLength = 50, containerClass, labelClass, error, errorMessage, icon, buttons, notice, register, value, timeslots, ...props }, ref) => {
  const [ isShowModal, setShowModal ] = useState(false);

  const handleChange = (e) => {
    onChange(e);
  };

  const handleFocus = (e) => {
    onFocus && onFocus(e);
    setShowModal(true);
  };

  const handleBlur = (e) => {
    onBlur && onBlur(e);
    onUnFocus && onUnFocus(e);
  };

  const handleChangeDate = (value, utc, slot) => {
    onChange({ date: value, utc, slot });
    setShowModal(false);
  };

  return (
    <div className={classNames(styles.container, fullWidth && styles.fullWidth, containerClass, hidden && styles.hidden)}>
      {label && <label className={classNames(styles.label, labelClass)} htmlFor={id}>{label}</label>}
      <div className={styles.inputContainer}>
        <input
          {...props}
          {...register}
          id={id}
          name={name}
          type={type}
          onChange={handleChange}
          onClick={() => setShowModal(true)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={classNames(styles.input, className, (icon || buttons) && styles.withIcon, error && 'error')}
          maxLength={maxLength}
          value={value ? moment(value.date).utcOffset(value.utc.utc).format('DD.MM.yyyy HH:mm') : ''}
          readOnly
          ref={ref}
        />
        <span className={styles.icons}>
          {buttons && <span className={styles.button}>{buttons}</span>}
          <span className={styles.icon}><img src="/icons/calendar.svg" alt="" /></span>
        </span>
      </div>
      {(error && errorMessage) &&
        <span className={styles.errorText}>{errorMessage}</span>
      }
      {notice &&
        <span className={styles.notice}>{notice}</span>
      }
      <DateTimeModal
        open={isShowModal}
        onClose={() => setShowModal(false)}
        onChange={handleChangeDate}
        dateValue={value?.date}
        gmtValue={value?.utc}
        timeslots={timeslots}
      />
    </div>
  );
});

Input.displayName = 'Input';

export default Input;