import { useContext, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'next-i18next';
import ReactDatePicker from 'react-datepicker';
import { isWeekend, addBusinessDays, max, add } from 'date-fns';
import moment from 'moment';
import { LoadingIndicator, Modal, ThemeContext } from '@forma/forma-ui-kit';
import ClientWrap from '@/components/ClientWrap';
import TimezoneSelect from '../TimezoneSelect';
import TimeItems from '../TimeItems';

import styles from './date-time-modal.module.css';

const getUserTimezone = () => {
  return moment().format('Z');
};

const DateTimeModal = ({ open, onClose, onChange, dateValue, gmtValue, timeslots }) => {
  const { t, i18n } = useTranslation();
  const { viewport } = useContext(ThemeContext);
  const [ slot, setSlot ] = useState(timeslots?.find(({ id }) => id === dateValue?.slot));
  const [ value, setValue ] = useState(dateValue || moment(addBusinessDays(new Date(), 1)).format('YYYY-MM-DDTHH:mm:ssZ'));
  const [ utc, setUtc ] = useState(gmtValue || { utc: getUserTimezone() });
  const [ step, setStep ] = useState(0);

  const handleChangeDate = (date) => {
    setValue(date.toISOString().replace('Z', utc.utc));
    setSlot(null);
  };

  const handleChangeTime = (id, date) => {
    setValue(date);
    setSlot(id);
  };

  const handleChangeUTC = (value) => {
    setUtc(value);
  };

  const handleClickSave = () => {
    onChange(value, utc, slot);
    setStep(0);
  };

  const handleClickNext = () => {
    setStep(prev => prev+1);
  };

  const dates = useMemo(() => timeslots?.reduce((acc, current) => {
    const date = current.date.slice(0, 10);
    const slot = { id: current.id, date: current.date, available: current.available };
    const next = {
      date: date,
      dateObj: new Date(date),
      available: current.available || acc[date]?.available || false,
      slots: acc[date] ? [ ...acc[date].slots, slot ] : [ slot ]
    };
    return ({ ...acc, [date]: next });
  }, {}), [timeslots]);


  const disabledDates = useMemo(() => dates && Object.values(dates)
    .filter(({ available, dateObj }) => !available || isWeekend(dateObj))
    .map(({ dateObj }) => dateObj), [dates]);

  return (
    <ClientWrap>
      <Modal
        open={open}
        onClose={onClose}
        title={viewport === 'phone' && (
          <div className={styles.modalTitle}>
            {step === 0 && t('date')}
            {step === 1 && (
              <><ReactSVG src="/icons/arrow-left.svg" className={styles.modalTitleIcon} wrapper="span" onClick={() => setStep(0)} /> {t('time')}</>
            )}
          </div>
        )}
        buttons={[
          {
            className: styles.modalButton,
            viewStyle: 'primary',
            onClick: (viewport === 'phone' && step !== 1) ? handleClickNext : handleClickSave,
            children: (viewport === 'phone' && step !== 1) ? t('next') : t('save'),
            closing: (viewport !== 'phone' || step === 1),
            disabled: (viewport !== 'phone' || step === 1) && !slot
          }
        ]}
      >
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            {!timeslots ? (
              <div className={styles.loader}>
                <LoadingIndicator color="var(--primary-color)" />
              </div>
            ) : (
              <>
                {(viewport !== 'phone' || step === 0) && (
                  <div className={styles.calendar}>
                    <ReactDatePicker
                      selected={new Date(value)}
                      onChange={handleChangeDate}
                      excludeDates={disabledDates}
                      minDate={addBusinessDays(new Date(), 1)}
                      maxDate={dates ? max(Object.values(dates).map(({ dateObj }) => dateObj)) : add(new Date(), { months: 1 })}
                      previousMonthButtonLabel={
                        <img src="/icons/arrow-left.svg" className={styles.arrow} width="22px" height="16px" alt="" />
                      }
                      nextMonthButtonLabel={
                        <img src="/icons/arrow-right.svg" className={styles.arrow} width="22px" height="16px" alt="" />
                      }
                      locale={i18n.language}
                      inline
                    />
                  </div>
                )}

                {(viewport !== 'phone' || step === 1) && (
                  <div className={styles.time}>
                    <div className={styles.timeHead}>
                      {viewport !== 'phone' && t('what_is_the_best_time')}
                      <TimezoneSelect value={utc} onChange={handleChangeUTC} />
                    </div>
                    <div className={styles.timeItems}>
                      <TimeItems
                        utc={utc}
                        slots={(dates && dates[moment(value).format('yyyy-MM-DD')]) && dates[moment(value).format('yyyy-MM-DD')].slots}
                        value={slot}
                        onChange={handleChangeTime}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Modal>
    </ClientWrap>
  );
};

export default DateTimeModal;
