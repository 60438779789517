import classNames from 'classnames';
import moment from 'moment';

import styles from './time-items.module.css';

const TimeItems = ({ utc, slots, value, onChange }) => {
  return (
    <div className={styles.root}>
      <div className={styles.items}>
        {slots?.map(({ id, date, available }) => (
          <div
            className={classNames(styles.item, !available && styles.disabled, value === id && styles.selected)}
            onClick={() => onChange(id, date)}
            tabIndex="0"
            key={id}
          >
            {moment(date).utcOffset(utc.utc).format('HH:mm')}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimeItems;
