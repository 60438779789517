const MetaTags = ({ title, description = '', keywords = '', canonicalUrl = '' }) => {
  const image = '/images/og_image.png';
  return (
    <>
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={image} />
      <link rel="icon" href="/favicon.ico" />
    </>
  );
};

export default MetaTags;
