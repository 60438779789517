import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';

const ControlField = forwardRef(({ component: Component, name, control, ...props }, ref) => {
  if (!Component) return null;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ...rest }, formState }) => (
        <Component
          {...props}
          {...rest}
          value={value}
        />
      )}
    />
  );
});

ControlField.displayName = 'ControlField';

export default ControlField;